<script setup lang="ts">
import { joinPath, parseSizeToGrid, resolveNodeId } from '@manager'
import type { IManagerGroupEmits, IManagerGroupProps } from './types'
import { createState } from '~/composables/createState'

const props = withDefaults(defineProps<IManagerGroupProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerGroupEmits>()

const path = computed(() => joinPath(props.parentPath, props.node.name))

const colSize = computed(() => parseSizeToGrid(props.node.size))

const parentValue = useVModel(props, 'parentValue', emit, {
  passive: true,
  deep: true,
})
const state = createState(parentValue)
</script>

<template>
  <!-- Group -->
  <div :data-path="path" :class="[colSize, 'relative space-y-4']">
    <slot name="header" />
    <slot name="body" v-bind="{ state, path }">
      <ManagerSharedGroupGrid>
        <slot v-bind="{ state, path }">
          <ManagerNode
            v-for="childNode in node.nodes"
            :key="resolveNodeId(childNode)"
            v-model:parent-value="parentValue"
            :node="childNode"
            :parent-path="path"
            :parent-node="node"
          />
        </slot>
      </ManagerSharedGroupGrid>
    </slot>
    <slot name="footer" />
  </div>
</template>
